import { customAlphabet } from 'nanoid/non-secure';

export const handleClipBoard = async (colHead: string[]): Promise<{}[]> => {
  //colHead array of column head strings
  let clip = await navigator.clipboard.readText();
  let clips: string[] = clip.split('\r\n');
  let clips2d = clips.map((el) => {
    return el.split('\t');
  });
  let y: { [key: string]: string }[] = [];
  clips2d.forEach(() => {
    y.push({});
  });
  for (let i = 0; i < clips2d.length; i++) {
    clips2d[i].forEach((el, j) => {
      y[i][colHead[j]] = el;
    });
  }
  return y;
};

export const getDashedDate = (date?: Date) => {
  //dd-MMM-YYYY
  // https://stackoverflow.com/questions/2388115/get-locale-short-date-format-using-javascript
  if (!date) return date;
  const d = new Date(date);
  const dd = d.toLocaleDateString('en', { day: '2-digit' });
  const MM = d.toLocaleDateString('en', { month: 'short' });
  const yyyy = d.toLocaleDateString('en', { year: 'numeric' });
  return `${dd}-${MM}-${yyyy}`;
};

export const getDate = (date?: string | Date | null): any => {
  let d;
  if (date) d = new Date(date);
  else d = new Date();
  function pad(s: any) {
    return s < 10 ? '0' + s : s;
  }
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};
export const getNextDay = (date: any) => {
  let tomorrow;
  if (date) tomorrow = new Date(date);
  else tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return getDate(tomorrow);
};
export const getPrevDay = (date: any) => {
  let prev;
  if (date) prev = new Date(date);
  else prev = new Date();
  prev.setDate(prev.getDate() - 1);
  return getDate(prev);
};
//deleting this function will cause bad request cos .net core wont accept "" as null. You must handle the conversion
//also dates initialized as current day from input component wont be submitted anymore
//This also overrides wrong state values wth original dom values
export const convertData = (
  //@ts-proper
  // e: { target: HTMLFormElement | null }
  e: { target?: HTMLFormElement | null }
): {} => {
  // const target = e.target as typeof e.target & {
  //   elements: HTMLElement[];
  // };
  if (!e.target) return {};
  const y: { [key: string]: any } = {};
  (Array.from(e.target.elements) as Array<HTMLInputElement | HTMLSelectElement>).forEach((el) => {
    const dt = el.getAttribute('data-type');
    if (dt !== null) {
      switch (dt) {
        case 'string':
          y[el.name] = el.value === '' || el.value === null ? null : el.value;
          break;
        case 'bool':
          y[el.name] = el.value === '' || el.value === null ? null : JSON.parse(el.value);
          break;
        case 'int':
          y[el.name] = el.value === '' || el.value === null ? null : +el.value;
          break;
        case 'cur':
          y[el.name] = el.value === '' || el.value === null ? null : +el.value.replace(/,/g, '');
          break;
        case 'date':
          y[el.name] = el.value === '' || el.value === null ? null : new Date(el.value);
          break;
        default:
          y[el.name] = el.value;
          break;
      }
    } else if (el.type === 'checkbox' && el instanceof HTMLInputElement) {
      y[el.name] = el.checked;
    } else if (el.type === 'submit') {
    } else {
      // y[el.name] = el.value;
    }
  });
  return y;
};
//for checking if all values passes are not null
//if true all args are not null

export const checkNull = (...args: any[]) => {
  return args.every((el) => {
    return !!el;
  });
};
//this mutates the data
export const parseData = (formData: { [key: string]: any }, r: { [key: string]: any }) => {
  const x = { ...formData };
  Object.keys(x).forEach((e) => {
    switch (r[e]) {
      case 'string':
        // eslint-disable-next-line
        x[e] === '' || x[e] === null ? (x[e] = null) : (x[e] = x[e]);
        break;
      case 'bool':
        x[e] === '' || x[e] === null
          ? (x[e] = null)
          : x[e] === 'true' || x[e] === 'false'
          ? (x[e] = JSON.parse(x[e]))
          : x[e] === true || x[e] === false
          ? // eslint-disable-next-line
            (x[e] = x[e])
          : (x[e] = null);
        break;
      case 'date':
        x[e] === '' || x[e] === null ? (x[e] = null) : (x[e] = new Date(x[e]));
        break;
      case 0:
        x[e] === '' || x[e] === null ? (x[e] = null) : (x[e] = +x[e]);
        break;
      default:
        // x[e] = x[e];
        break;
    }
  });
  return x;
};
export const convertCellData = (state: any[] | unknown, data: any, prop: string, code: string): string => {
  checkNull(state, data);
  if (!state) return '';
  //we do to string because filterParamsValueformatter returns number as string
  const r = (state as any[]).find((el) => el[code]?.toString() === data?.toString());
  return r ? r[prop] : '';
};

export const boolParser = (data: any) => {
  return JSON.parse(data.newValue);
};

export function numberParser(params: any) {
  return params.newValue === undefined ? params.newValue : Number(params.newValue);
  // if (params.newValue === undefined) return params.newValue;
  // const toNumber = Number(params.newValue);
  // if (isNaN(toNumber)) return undefined;
  // return toNumber;
}
export function isFirstColumn(params: any) {
  var displayedColumns = params.api.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

export const customId = () => customAlphabet('0123456789', 8)();

const splitWord = (s: string): string[] => {
  var re,
    match,
    output = [];
  // re = /[A-Z]?[a-z]+/g
  re = /([A-Za-z]?)([a-z]+)/g;

  /*
	matches example: "oneTwoThree"
	["one", "o", "ne"]
	["Two", "T", "wo"]
	["Three", "T", "hree"]
	*/

  match = re.exec(s);
  while (match) {
    // output.push(match.join(""));
    output.push([match[1].toUpperCase(), match[2]].join(''));
    match = re.exec(s);
  }

  return output;
};

export const splitCase = (s: string): string => {
  return splitWord(s).join(' ');
};

export const enterToNextLine = (e: any, addRow: () => void) => {
  var keyPressed = e.event.key;
  var renderedRowCount = e.api.getDisplayedRowCount();
  if (keyPressed === 'Enter' && e.rowIndex === renderedRowCount - 1 && e.colDef.field === 'referenceNo') {
    const rowNode = addRow();
    // scrolls to the first row
    e.api.ensureIndexVisible(renderedRowCount);
    // scrolls to the first column
    //@ts-ignore
    var firstCol = e.api.getAllDisplayedColumns()[0];
    e.api.ensureColumnVisible(firstCol);
    // start editing cell
    e.api.redrawRows({
      //@ts-ignore
      rowNodes: [rowNode.add[0]],
    });
    e.api.startEditingCell({
      rowIndex: renderedRowCount,
      colKey: firstCol,
    });
  }
};

export const base64ToBlob = (base64: any, contentType = '') => {
  const base64WithoutPrefix = base64.replace(/^data:[^;]+;base64,/, '');
  const byteCharacters = atob(base64WithoutPrefix);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

export const getMimeTypeFromBase64 = (base64String: any) => {
  const matches = base64String.match(/^data:([A-Za-z-+]+);base64/);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return null;
};

const getMimeType = (fileType: any) => {
  const mimeTypes: any = {
    pdf: 'application/pdf',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    csv: 'text/csv',
  };
  return mimeTypes[fileType.toLowerCase()];
};

export const openBase64StringInNewTab = (filename = '', base64 = '') => {
  if (!filename || !base64) return;

  const extension = filename?.split('.')?.pop()?.toLowerCase();

  const blob = base64ToBlob(base64, getMimeType(extension));
  const mimeType = getMimeType(extension) || getMimeTypeFromBase64(base64);

  if (blob && mimeType) {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    //a.download = filename;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
    //window.open(url);
  } else {
    return { error: 'Invalid file type or Base64 string', filename };
  }
};

export const randomTransId = () => -Math.round(Math.random() * 100000);
